// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~retinajs/dist/_retina.scss';

.action-sheet-button {
  .button-inner {
    font-size: 16px;
    font-weight: bold;
  }
  .icon {
    margin-right: 10px;
  }
}

.button,
button {
  &:active {
    opacity: 0.7;
  }
}

.vertical-center {
  display: flex;
  align-items: center;
}

ion-modal,
.ios.show-modal {
  --background: transparent;
  background: transparent;
}

ion-alert {
  ion-backdrop {
    display: block;
  }
  .alert-button.delete-button {
    color: red;
  }

  &.error-alert {
    .alert-sub-title {
      color: firebrick;
    }
  }
}

ion-alert {
  ion-backdrop {
    display: block;
  }
  .alert-button.delete-button {
    color: red;
  }

  &.error-alert {
    .alert-sub-title {
      color: firebrick;
    }
  }
  &.delete-account-alert {
    .alert-title {
      color: firebrick;
    }
    .alert-message {
      font-size: 16px;
      line-height: 24px;
    }
    .bold {
      font-weight: bold;
    }
    .red {
      color: firebrick;
    }
    .alert-confirm-delete {
      color: firebrick;
    }
  }
}


.plt-tablet,
.plt-desktop {
  body {
    background: var(--theme-color-darkPurple);

    ion-app {
      margin: 0 auto;
    }
  }
}

.button-solid {
  &:hover {
    --background: inherit;
  }
}

.alert-sub-title.sc-ion-alert-ios {
  color: var(--theme-color-blue);
  font-weight: bold;
}

.alert-confirm {
  .alert-text-confirm {
    color: #f04141;
    font-weight: bold;
  }
}



.return-address-info {
  .alert-section {
    margin: 8px 0;
    text-align: left;
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 8px;
    .label {
      font-weight: bold;
      color: var(--theme-color-blue);
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

.cdk-overlay-pane {
  overflow: hidden;
}

mat-bottom-sheet-container.mat-bottom-sheet-container {
  overflow: hidden;
  padding: 8px 0;
  margin-bottom: -10px;
  max-height: 85vh;
  &.payment-sheet {
    max-height: 100vh;
    overflow-y: scroll;
  }
}
